import "../styles/Footer.css";

function Footer() {
    return (
        <div className="Footer">
            <hr />
            <p>&#169; 2024 Mitchell Garrett</p>
        </div>
    );
}

export default Footer;
